<template>
  <div id="select-partner-container">
    <v-autocomplete
      attach="#select-partner-container"
      v-model="name"
      :items="items"
      :search-input.sync="search"
      outlined
      dense
      hide-details
      label="Partner"
      clearable
    />
  </div>
</template>

<script>

export default {
  name: 'SelectPartner',

  props: ['partnerId', 'partnerUniqueCode', 'partnerName', 'partnerServices'],

  data: () => ({
    partners: [],
    name: '',
    search: ''
  }),

  watch: {
    name (val) {
      this.partner = this.partners.find(partner => partner.name === val)
      if (this.partner) {
        this.$emit('update:partnerId', this.partner.id)
        this.$emit('update:partnerUniqueCode', this.partner.uniqueCode)
        this.$emit('update:partnerName', this.partner.name)
        this.__getPartnerServices(val, this.updatePartnerServices)
      }
    }
  },

  computed: {
    items () {
      return this.partners.map(partner => partner.name)
    }

    // partner: {
    //   get () {
    //     return this.partnerId ? this.partners.find(item => item.id === this.partnerId) : null
    //   },
    //   set (reseller) {
    //     if (reseller) this.__getPartnerServices(reseller.id, this.updatePartnerServices)
    //
    //     const { id: resellerId = null, uniqueCode: partnerUniqueCode = '', name: partnerName = '' } = reseller || {}
    //
    //     this.$emit('update:partnerId', resellerId)
    //     this.$emit('update:partnerUniqueCode', partnerUniqueCode)
    //     this.$emit('update:partnerName', partnerName)
    //
    //     customerController.updateProperty('resellerId', resellerId)
    //     customerController.updateProperty('partnerUniqueCode', partnerUniqueCode)
    //     customerController.updateProperty('partnerName', partnerName)
    //   }
    // }
  },

  methods: {
    updatePartnerServices (services) {
      this.$emit('update:partnerServices', services)
      // customerController.setPartnerServices(services)
    },

    getResellersList (list) {
      this.partners = list.map(partner => ({
        name: partner.company.name,
        id: partner._id,
        uniqueCode: partner.uniqueCode
      }))
    }
  },

  created () {
    this.__getResellersList(this.getResellersList)
  }
}
</script>

<style scoped>
#select-partner-container {
  position: relative;
}
#select-partner-container > .v-autocomplete__content.v-menu__content {
  position: relative !important;
  top: 204px !important;
  left: 616px !important;
  max-height: 280px !important;
}
</style>
